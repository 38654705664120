import { Link as LinkUi } from '@bloom/ui';
import { css } from '@emotion/css/macro';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ExternalLinkConfirm from 'components/ExternalLink/ExternalLinkConfirm';
import type { ElementType, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import type { Theme } from 'theme/theme';

// no color, opacity or spacing for Titles
const CustomLink = styled(Link)(
  ({ theme }) => css`
    font-weight: ${theme.paragraph3.fontWeight};
    font-size: ${theme.paragraph3.fontSize};
    line-height: ${theme.paragraph3.lineHeight};
    text-decoration: none;
    color: ${theme.accentColor};
  `
);

type ExternalLinkProps = {
  children: ReactNode;
  href: string;
  className?: string;
  style?: Record<string, string>;
  download?: string | boolean;
};

const ExternalLink: FC<ExternalLinkProps> = ({ children, href, className, style, download }) => (
  <ExternalLinkConfirm href={href}>
    <LinkUi
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      style={style}
      download={download}
    >
      {children}
    </LinkUi>
  </ExternalLinkConfirm>
);

/**
 * Link with theme style
 */
const RawLinkStyled = withTheme(
  styled.a(
    ({ theme }) => css`
      color: ${theme.mainColor};
      font-weight: ${theme.paragraph1.fontWeight};
      font-size: ${theme.paragraph1.fontSize};
      line-height: ${theme.paragraph1.lineHeight};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    `
  )
);

const RawLinkSmallStyled = withTheme(
  styled.a(
    ({ theme }) => css`
      color: ${theme.mainColor};
      font-weight: ${theme.paragraph3.fontWeight};
      font-size: ${theme.paragraph3.fontSize};
      line-height: ${theme.paragraph3.lineHeight};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    `
  )
);

/**
 * HOC to make a "a" open in a new tab
 */
const withNewTab =
  <TProps,>(Component: ElementType) =>
  (props: TProps) => <Component target="_blank" rel="noopener noreferrer" {...props} />;

const ExternalLinkStyled = withNewTab<{ theme?: Theme; href: string; children: ReactNode }>(
  RawLinkStyled
);
const ExternalLinkSmallStyled = withNewTab<{ theme?: Theme; href: string; children: ReactNode }>(
  RawLinkSmallStyled
);

export { ExternalLink, ExternalLinkSmallStyled, ExternalLinkStyled, CustomLink as Link };
